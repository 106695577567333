<template>
    <div>
        <div class="row">
            <div class="col-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div class="mr-3">
                                <div class="green" v-if="queueSize === 0">
                                    <svg viewBox="0 0 20 20" fill="currentColor" class="h-6 w-6 border-green-large">
                                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                    </svg>
                                </div>
                                <div class="amber" v-else-if="queueSize < 1000">
                                    <svg viewBox="0 0 24 24" class="h-6 w-6 border-amber-large">
                                        <path fill="currentColor" d="M 11,4L 13,4L 13,15L 11,15L 11,4 Z M 13,18L 13,20L 11,20L 11,18L 13,18 Z" />
                                    </svg>
                                </div>
                                <div class="red" v-else>
                                    <svg viewBox="0 0 20 20" fill="currentColor" class="h-6 w-6 border-red-large">
                                        <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                    </svg>
                                </div>
                            </div>
                            <div>
                                <h2 class="mb-0">Today's Speed</h2>
                                <p class="text-muted text-sm mb-0">Today's Max: {{todaySpeed.max | number}}ms Current Average: {{todaySpeed.average | number}}ms</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div class="mr-3">
                                <div class="green" v-if="todayTolerance.percent >= 0.0002 || todayTolerance.percent <= 0.0002">
                                    <svg viewBox="0 0 20 20" fill="currentColor" class="h-6 w-6 border-green-large">
                                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                    </svg>
                                </div>
                                <div class="red" v-else>
                                    <svg viewBox="0 0 20 20" fill="currentColor" class="h-6 w-6 border-red-large">
                                        <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                    </svg>
                                </div>
                            </div>
                            <div>
                                <h2 class="mb-0">Today's Tolerance</h2>
                                <p class="text-muted text-sm mb-0">Cash: {{todayTolerance.cash | currency}} Percent: {{todayTolerance.percent | number(2, true) | percentage}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div class="mr-3">
                                <div class="green" v-if="queueSize === 0">
                                    <svg viewBox="0 0 20 20" fill="currentColor" class="h-6 w-6 border-green-large">
                                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                    </svg>
                                </div>
                                <div class="amber" v-else-if="queueSize < 1000">
                                    <svg viewBox="0 0 24 24" class="h-6 w-6 border-amber-large">
                                        <path fill="currentColor" d="M 11,4L 13,4L 13,15L 11,15L 11,4 Z M 13,18L 13,20L 11,20L 11,18L 13,18 Z" />
                                    </svg>
                                </div>
                                <div class="red" v-else>
                                    <svg viewBox="0 0 20 20" fill="currentColor" class="h-6 w-6 border-red-large">
                                        <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                    </svg>
                                </div>
                            </div>
                            <div>
                                <h2 class="mb-0">Database Status</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div class="mr-3">
                                <div class="green" v-if="queueSize === 0">
                                    <svg viewBox="0 0 20 20" fill="currentColor" class="h-6 w-6 border-green-large">
                                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                    </svg>
                                </div>
                                <div class="amber" v-else-if="queueSize < 1000">
                                    <svg viewBox="0 0 24 24" class="h-6 w-6 border-amber-large">
                                        <path fill="currentColor" d="M 11,4L 13,4L 13,15L 11,15L 11,4 Z M 13,18L 13,20L 11,20L 11,18L 13,18 Z" />
                                    </svg>
                                </div>
                                <div class="red" v-else>
                                    <svg viewBox="0 0 20 20" fill="currentColor" class="h-6 w-6 border-red-large">
                                        <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                    </svg>
                                </div>
                            </div>
                            <div>
                                <h2 class="mb-0">Rabbit Queues</h2>
                                <p class="text-muted text-sm mb-0">{{queueSize | number(0)}} items in the queues</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Open Trades</h4>
                    </div>
                    <div class="card-body">
                        <b-table id="opentrades-table" :items="openTrades" :fields="openTradesColumns" responsive class="text-center" show-empty :per-page="50" :current-page="openTradesCurrentPage">
                            <template slot="empty">
                                No results found
                            </template>
                            <template v-slot:cell(estimatedExitTime)="data">
                                <vue-countdown :time="data.value" :transform="formatCountdown" v-if="data.value !== 'N/A'">
                                    <template slot-scope="props">{{props.hours}}:{{props.minutes}}:{{props.seconds}}</template>
                                </vue-countdown>
                                <span v-else>{{data.value}}</span>
                            </template>
                        </b-table>
                        <div class="row mt-3">
                            <div class="col-12 d-flex align-items-center justify-content-start">
                                <b-pagination v-model="openTradesCurrentPage" :total-rows="openTrades.length" :per-page="50" aria-controls="opentrades-table" class="ml-auto"></b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';
import { differenceInHours, differenceInMinutes, differenceInSeconds, parseJSON } from 'date-fns';

export default {
    data() {
        let that = this;
        return {
            hasSetupListeners: false,
            updateInterval: null,
            queueSize: 0,
            todayTolerance: {
                cash: 0,
                percent: 0
            },
            todaySpeed: {
                max: 0,
                average: 0
            },
            openTrades: [],
            openTradesColumns: [
                {
                    key: 'InternalStrategy',
                    label: 'Internal Strategy',
                    sortable: true
                },
                {
                    key: 'Market',
                    label: 'Market',
                    sortable: true
                },
                {
                    key: 'CashTolerance',
                    label: 'Cash Tolerance',
                    sortable: true,
                    formatter: function(value) {
                        return that.$options.filters.currency(that.$options.filters.number(value));
                    }
                },
                {
                    key: 'estimatedExitTime',
                    label: 'Expected Exit',
                    sortable: true
                }
            ],
            openTradesCurrentPage: 1
        };
    },
    components: {
        VueCountdown
    },
    mounted() {
        this.requests();
        this.updateInterval = setInterval(() => this.requests(), 1000);
    },
    beforeDestroy() {
        clearInterval(this.updateInterval);
        this.updateInterval = null;
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('systemhealth', this.onSystemHealth);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('systemhealth', this.onSystemHealth);
                this.hasSetupListeners = true;
            }

            let account = this.$store.state.activeAccount;

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'SystemHealth',
                args: {}
            });
        },
        onSystemHealth(event) {
            this.queueSize = event.response.RabbitQueueSize;

            this.todayTolerance.cash = event.response.TodaysTolerance.Item1;
            this.todayTolerance.percent = event.response.TodaysTolerance.Item2;

            this.todaySpeed.max = event.response.TodaysSpeed.Item1;
            this.todaySpeed.average = event.response.TodaysSpeed.Item2;

            let now = parseJSON(event.response.CurrentDateTime.split('.')[0]);
            console.log(now);
            for(let trade of event.response.OpenTrades) {
                let difference = parseJSON(trade.estimatedExitTime) - now;
                console.log(parseJSON(trade.estimatedExitTime));
                console.log(difference);
                if(difference > 0) {
                    trade.estimatedExitTime = difference;
                } else {
                    trade.estimatedExitTime = 'N/A';
                }
            }

            this.openTrades = event.response.OpenTrades;
        },
        formatCountdown(props) {
            Object.entries(props).forEach(([key, value]) => {
                // Adds leading zero
                const digits = value < 10 ? `0${value}` : value;
                props[key] = digits;
            });

            return props;
        }
    }
}
</script>

<style lang="scss" scoped>
.green {
    color: #48BB78;
}

.border-green-large {
    border: 5px solid #48BB78;
    border-radius: 50%;
}

.amber {
    color: #ED8936;
}

.border-amber-large {
    border: 5px solid #ED8936;
    border-radius: 50%;
}

.red {
    color: #E53E3E;
}

.border-red-large {
    border: 5px solid #E53E3E;
    border-radius: 50%;
}

.h-6 {
    height: 6rem;
    
}

.w-6 {
    width: 6rem;
}

</style>