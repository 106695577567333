<template>
  <div id="page-wrapper">
    <top-header></top-header>
    <side-navbar></side-navbar>
    <main-content title="System Health">
      <system-health></system-health>
    </main-content>
  </div>
</template>

<script>
import TopHeader from "@/components/TopHeader";
import SideNavbar from "@/components/SideNavbar";
import MainContent from "@/components/MainContent";
import SystemHealth from "@/widgets/SystemHealth";

export default {
  components: {
    TopHeader,
    SideNavbar,
    MainContent,
    SystemHealth,
  },
};
</script>
