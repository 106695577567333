import { render, staticRenderFns } from "./SystemHealth.vue?vue&type=template&id=71d86f0b&"
import script from "./SystemHealth.vue?vue&type=script&lang=js&"
export * from "./SystemHealth.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports